import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.scss";
import "react-toastify/dist/ReactToastify.css";
import { Suspense } from "react";
import ProtectedRoutes from "./Middleware/ProtectedRoutes";
import Loading from "./Components/Loading/Loading";

const Login = lazy(() => import("./Pages/Login"));
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const ProductionProblem = lazy(() => import("./Pages/ProductionProblem"));
const History = lazy(() => import("./Pages/History"));
const ViewChat = lazy(() => import("./Pages/ViewChat"));
const AddRequirement = lazy(() => import("./Pages/AddRequirement"));
const ManufacturingPacking = lazy(() => import("./Pages/ManufacturingPackage"));

const App = () => {
  // console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  return (
    <Router>
      <Suspense
        fallback={
          <Loading color={"rgba(1, 18, 34, 1)"} size={42} height={true} />
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            {/* Gaurav Bassi Sir Modules */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/add-requirements" element={<AddRequirement />} />
            <Route path="/production-problem" element={<ProductionProblem />} />
            <Route path="/history" element={<History />} />
            <Route path="/view-chat" element={<ViewChat />} />

            {/* Hardeep Sir Modules */}
            <Route
              path="/manufacturing-packing"
              element={<ManufacturingPacking />}
            />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
